/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

html,body {
  height:100%;
}

ion-content{
    --ion-background-color:#000;
}

.modal-container {
  position: fixed;
  top: 3vh;
  left: 3vw;
  width: 94vw;
  height: 94vh;
  //background-color: darkslategray;
  z-index: 999;
  // padding: 16px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.26);
  display: grid;

}

.quest-modal-container {
  position: fixed;
  top: 3vh;
  left: 3vw;
  width: 94vw;
  height: 94vh;
  //background-color: darkslategray;
  z-index: 9999;
  // padding: 16px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.26);
  display: grid;

}

.popup-container {
  position: fixed;
  top: 6vh;
  left: 6vw;
  width: 88vw;
  height: 88vh;
  //background-color: darkslategray;
  z-index: 99999;
  box-shadow: 0 2px 8px rgba(0,0,0,0.26);
  display: grid;

}

.backdrop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.75);
  z-index: 100;
}

.backdrop-quest{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.75);
  z-index: 1000;
}

.backdrop-over{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.75);
  z-index: 10000;
}

.productionLinesSeparator{
  height: 100px;
  width: 1px;
  background-color: #000;
  display: inline-block;
}

.baggage-item-image{
  display: block;
  width: 60px;
  height: 60px;

  &.weapon{
    width: 100px;
    height: 100px;
  }
}

.baggage-item{
  display: inline-block;
  margin-right: 10px;
  width: 100px;
  cursor: pointer;
}

/* The animation code */
@keyframes strollEventFind {
  0% {
    opacity: 1;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
  }
  20% {
    top: 50%;
    left: 50%;
    transform: scale(1);
  }
  95% {
    top: 0;
    left: 0;
    transform: scale(0.1);
    margin-left: -50px;
    margin-top: -50px;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    opacity: 0;
  }
}

.stroll-event-find {
  position: fixed;
  display: grid;
  top: -100px;
  left: -100px;
  opacity: 0;
  z-index: 199999;
  animation-name: strollEventFind;
  animation-duration: 2s;
}

.stroll-event-find div {
  margin: auto;
  text-align: center;
  color:goldenrod;
  font-weight: bold;
}
